import React, { useContext } from "react";
import PropTypes from "prop-types";
import { formatNumber } from "../../../utils/miscFunctions";

import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { DesktopContext } from "../../../App";

function InterestOnlySummary({ maximumMortgage }) {
  const desktop = useContext(DesktopContext);

  let summaryHeadingSize = "1.5rem";
  let summaryHorizPadding = 2;
  //let summaryLabelSize = "1.2rem";
  //let summaryMonthlyRepaymentSize = "1.7rem";
  //let summaryTotalRepaymentSize = "1.5rem";
  if (desktop) {
    summaryHeadingSize = "1.8rem";
    summaryHorizPadding = 2;
    //summaryLabelSize = "1.5rem";
    //summaryMonthlyRepaymentSize = "2.0rem";
    //summaryTotalRepaymentSize = "1.7rem";
  }

  return (
    <>
      <Card
        sx={{
          boxShadow: 0,
          backgroundColor: "#19899D",
          color: "#FFF",
          pt: 4,
          pb: 4,
          pl: summaryHorizPadding,
          pr: summaryHorizPadding,
          borderRadius: "4px",
        }}
      >
        <Box display="flex" justifyContent="center">
          <Typography
            component="span"
            sx={{
              fontWeight: "500 !important",
              fontSize: summaryHeadingSize,
              mt: 0,
              mb: 1.5,
            }}
          >
            You Could Borrow
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center">
        <Typography
          sx={{ fontSize: "0.9rem", mt: 2.5, fontWeight: 400 }}
          component="span"
        >
          up to&nbsp;&nbsp;
        </Typography>
        <Typography
          sx={{
            fontWeight: 500,
            color: "#FFF",
            fontSize: "1.6rem",
            mt: 0.8,
          }}
          component="span"
        >
          {formatNumber(maximumMortgage)}
        </Typography>
        <Typography sx={{ fontSize: "1rem", mt: 2, pb: 2.5 }} component="span">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </Typography>
        </Box>
      </Card>
    </>
  );
}

InterestOnlySummary.propTypes = {
  maximumMortgage: PropTypes.number.isRequired,
};

export default InterestOnlySummary;
