import React, { useContext } from "react";
import PropTypes from "prop-types";
import { DesktopContext } from "../../../App";
import SubmitButton from "../../../components/submit/SubmitButtonNoGrid";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { yupSalary, yupPartnerSalary } from "../../../config/yupSchema";
import {
  Salary,
  PartnerSalary,
} from "../../../formGroups/PersonalDetailsGroup";
import Grid from "@mui/material/Grid";
import { useMediaQuery } from "@mui/material";

import FormDivider from "../../../components/divider/FormDivider";

export default function CalculatorInput({ onSubmit, formStep }) {
  const desktop = useContext(DesktopContext);
  //eslint-disable-next-line

  const schema = yup.object().shape({
    salary: yupSalary,
    partnerSalary: yupPartnerSalary,
  });

  const screenWidth = useMediaQuery((theme) => theme.breakpoints.down("md")); // Modify the breakpoint value as needed

  let gridSpacing = screenWidth ? 0 : 6;

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  var rightCol = 12;
  if (desktop) {
    //eslint-disable-next-line
    rightCol = 6;
  }

  return (
    <>
      <form>
        <FormDivider dividerText={"How Much Can I Borrow?"} />
        <Grid container spacing={gridSpacing} sx={{ mb: 1.35 }}>
          <Grid item md={6} xs={12}>
            <Salary control={control} required={true} />
          </Grid>
          <Grid item md={6} xs={12}>
            <PartnerSalary control={control} required={true} />
          </Grid>
        </Grid>
        <SubmitButton
          buttonText={formStep === 1 ? "Recalculate" : "Calculate"}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          isSubmitting={isSubmitting}
          buttonFooterText={""}
        />
      </form>
    </>
  );
}

CalculatorInput.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  formStep: PropTypes.number.isRequired,
};
