import React from "react";
import PropTypes from "prop-types";

import { calculateMaximumMortgage } from "./calculations";
import Box from "@mui/material/Box";

import SummaryComponent from "./SummaryComponent";

export default function Results(props) {
  const data = props.settedData;

  const maximumMortgage = calculateMaximumMortgage(
    data.salary,
    data.partnerSalary
  );

  console.log("maximumMortgage", maximumMortgage);

  return (
    <Box sx={{ mt: 7, mb: 4 }}>
      <SummaryComponent maximumMortgage={maximumMortgage} />
    </Box>
  );
}

Results.propTypes = {
  settedData: PropTypes.shape({
    salary: PropTypes.string.isRequired,
    partnerSalary: PropTypes.string,
  }).isRequired,
};
